/**
 * Finds the primary tracking metric from a list of tracking metrics in a given experiment.
 *
 * @param {Experiment} experiment - the experiment details
 * @returns {string} the primary tracking metric
 */
export default function getPrimaryMetric(experiment) {
    var _a;
    var primaryMetric = experiment.config.trackingMetrics.find(function (trackingMetric) { return trackingMetric.isPrimary; });
    return (_a = primaryMetric === null || primaryMetric === void 0 ? void 0 : primaryMetric.readableName) !== null && _a !== void 0 ? _a : '';
}
