var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Card from '@leafygreen-ui/card';
import Badge, { Variant as BadgeVariant } from '@leafygreen-ui/badge';
import IconButton from '@leafygreen-ui/icon-button';
import { Body, Overline } from '@leafygreen-ui/typography';
import styled from 'styled-components';
import Tooltip from '@leafygreen-ui/tooltip';
import Icon from '@leafygreen-ui/icon';
var ProgressText = styled(Body)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #3d4f58;\n"], ["\n  color: #3d4f58;\n"])));
var StyledHoldoutCard = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: #ffffff;\n  border: 1px solid #e8edeb;\n  border-radius: 24px;\n  height: 145px;\n  min-width: 400px;\n  box-shadow: none;\n"], ["\n  background: #ffffff;\n  border: 1px solid #e8edeb;\n  border-radius: 24px;\n  height: 145px;\n  min-width: 400px;\n  box-shadow: none;\n"])));
var HoldoutHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
var HoldoutTitle = styled(Body)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  color: #112733;\n  max-width: 210px;\n  overflow-wrap: break-word;\n"], ["\n  font-weight: 600;\n  color: #112733;\n  max-width: 210px;\n  overflow-wrap: break-word;\n"])));
var HoldoutMetricsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 45px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  gap: 45px;\n"])));
var HoldoutIconsWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 7px;\n  padding-bottom: 30px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 7px;\n  padding-bottom: 30px;\n"])));
var ProgressBar = styled.progress(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: -30px;\n  border-radius: 2px;\n  width: 100%;\n  height: 4px;\n\n  ::-webkit-progress-bar {\n    margin-top: -10px;\n    background-color: #e3fcf7;\n    border-radius: 2px;\n  }\n  ::-webkit-progress-value {\n    background-color: #00684a;\n    border-radius: 2px;\n  }\n"], ["\n  margin-top: -30px;\n  border-radius: 2px;\n  width: 100%;\n  height: 4px;\n\n  ::-webkit-progress-bar {\n    margin-top: -10px;\n    background-color: #e3fcf7;\n    border-radius: 2px;\n  }\n  ::-webkit-progress-value {\n    background-color: #00684a;\n    border-radius: 2px;\n  }\n"])));
var HoldoutCard = function (_a) {
    var _b, _c;
    var experiment = _a.experiment;
    var config = experiment.config;
    var _id = config._id, startDate = config.startDate, targetDuration = config.targetDuration;
    var experimentId = (_b = _id === null || _id === void 0 ? void 0 : _id.toString()) !== null && _b !== void 0 ? _b : '';
    var daysSinceStart = 0;
    var readableStartDate = '';
    if (startDate !== undefined) {
        var startTime = new Date(startDate).getTime();
        var currentTime = new Date().getTime();
        var timeSinceStart = Math.abs(startTime - currentTime);
        daysSinceStart = Math.ceil(timeSinceStart / (1000 * 3600 * 24));
        readableStartDate = new Date(startDate).toLocaleDateString();
    }
    return (<StyledHoldoutCard data-testid="holdout-card">
      <HoldoutHeader>
        <HoldoutTitle data-testid={"holdout-title-".concat(config._id)}>{config === null || config === void 0 ? void 0 : config.name}</HoldoutTitle>
        <HoldoutIconsWrapper>
          <Tooltip align="bottom" trigger={<IconButton aria-label="Edit" href={"/config/?id=".concat(config._id)}>
                <Icon glyph="Edit"/>
              </IconButton>}>
            Edit
          </Tooltip>
          <Tooltip align="bottom" trigger={<IconButton aria-label="Copy Experiment ID" onClick={function () { return navigator.clipboard.writeText(experimentId); }}>
                <Icon glyph="Copy"/>
              </IconButton>}>
            Copy Experiment ID
          </Tooltip>
          <Tooltip align="bottom" trigger={<IconButton aria-label="View" href={"/details/?experimentId=".concat(config._id)}>
                <Icon glyph="ArrowRight"/>
              </IconButton>}>
            View
          </Tooltip>
        </HoldoutIconsWrapper>
      </HoldoutHeader>
      <HoldoutMetricsWrapper>
        <div>
          <Overline>STATUS</Overline>
          <Badge variant={BadgeVariant.Green}>{config === null || config === void 0 ? void 0 : config.runningStatus}</Badge>
        </div>
        {config.targetDuration && (<div>
            <Overline>PROGRESS</Overline>
            <Tooltip align="right" trigger={<ProgressText>
                  {daysSinceStart}/{targetDuration} days
                  <ProgressBar value={daysSinceStart} max={targetDuration} style={ProgressBar.progress}></ProgressBar>
                </ProgressText>} style={{ whiteSpace: 'pre-wrap' }}>
              {"Start Date: ".concat(readableStartDate, "\nTarget Duration: ").concat(targetDuration, " days")}
            </Tooltip>
          </div>)}
        <div>
          <Overline>Allocation</Overline>
          <Badge variant={BadgeVariant.LightGray}>
            {(_c = config.variants.find(function (variant) { return variant.isControl; })) === null || _c === void 0 ? void 0 : _c.trafficPercentage}%
          </Badge>
        </div>
      </HoldoutMetricsWrapper>
    </StyledHoldoutCard>);
};
export default HoldoutCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
