var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useRouter } from 'next/router';
import Card from '@leafygreen-ui/card';
import { palette } from '@leafygreen-ui/palette';
import { V10Cell as Cell, V10Row as Row, V10Table as Table, V10TableHeader as TableHeader } from '@leafygreen-ui/table';
import { Body } from '@leafygreen-ui/typography';
import styled from 'styled-components';
import { ExpType, RunningStatus } from '../shared/types/db_schema/experimentConfig';
import getPrimaryMetric from '../utils/getPrimaryMetric';
var NameCell = styled(Cell)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 300px;\n  font-weight: 600;\n  word-break: break-word;\n  color: ", ";\n"], ["\n  max-width: 300px;\n  font-weight: 600;\n  word-break: break-word;\n  color: ", ";\n"])), palette.blue.base);
var StatusCell = styled(Cell)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 108px;\n"], ["\n  max-width: 108px;\n"])));
var DateCell = styled(Cell)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 108px;\n"], ["\n  max-width: 108px;\n"])));
var TagCell = styled(Cell)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 220px;\n  word-break: break-all;\n"], ["\n  max-width: 220px;\n  word-break: break-all;\n"])));
var ScopeCell = styled(Cell)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  max-width: 120px;\n"], ["\n  max-width: 120px;\n"])));
var ExpTypeCell = styled(Cell)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 120px;\n"], ["\n  max-width: 120px;\n"])));
var PrimaryMetricCell = styled(Cell)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 200px;\n"], ["\n  max-width: 200px;\n"])));
var VariantsCell = styled(Cell)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  max-width: 148px;\n"], ["\n  max-width: 148px;\n"])));
// emotion doesn't persist `displayName` values for styled components, and LG needs it internally for rendering cells. if taken out, the Cell won't be rendered
NameCell.displayName = 'Cell';
StatusCell.displayName = 'Cell';
DateCell.displayName = 'Cell';
TagCell.displayName = 'Cell';
ScopeCell.displayName = 'Cell';
ExpTypeCell.displayName = 'Cell';
PrimaryMetricCell.displayName = 'Cell';
VariantsCell.displayName = 'Cell';
var LaunchedStatus = styled(Body)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 600;\n"], ["\n  color: ", ";\n  font-weight: 600;\n"])), palette.green.dark1);
var ExperimentTableCard = styled(Card)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 16px;\n"], ["\n  margin-top: 16px;\n"])));
var StyledRow = styled(Row)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  &:hover {\n    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);\n    z-index: 1000;\n    position: relative;\n  }\n"], ["\n  cursor: pointer;\n  width: 100%;\n  height: 100%;\n  &:hover {\n    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);\n    z-index: 1000;\n    position: relative;\n  }\n"])));
var ExperimentTable = function (_a) {
    var experiments = _a.experiments;
    var shouldUseControlView = function (experiment) {
        var isAbType = experiment.config.expType === ExpType.AB;
        var isCmabType = experiment.config.expType === ExpType.CMAB;
        var isHoldoutType = experiment.config.expType === ExpType.HOLDOUT;
        return isAbType || isCmabType || isHoldoutType;
    };
    var router = useRouter();
    return (<ExperimentTableCard data-testid="experiment-table">
      <Table data={experiments} columns={[
            <TableHeader label="Experiment name"/>,
            <TableHeader label="Status"/>,
            <TableHeader label="Start date"/>,
            <TableHeader label="Tags"/>,
            <TableHeader label="Scope"/>,
            <TableHeader label="Type"/>,
            <TableHeader label="Primary metric"/>,
            <TableHeader label="Variants"/>,
        ]}>
        {function (_a) {
            var datum = _a.datum;
            return (<StyledRow key={datum.config.name} onClick={function () {
                    return router.push("/".concat(shouldUseControlView(datum) ? 'details' : 'mab', "?experimentId=").concat(datum.config._id));
                }}>
            <NameCell>{datum.config.name}</NameCell>
            <StatusCell>
              {datum.config.runningStatus === RunningStatus.LAUNCHED ? (<LaunchedStatus>{datum.config.runningStatus}</LaunchedStatus>) : (datum.config.runningStatus)}
            </StatusCell>
            <DateCell>
              {datum.config.startDate
                    ? new Date(datum.config.startDate).toLocaleDateString('en-US', {
                        year: '2-digit',
                        month: 'numeric',
                        day: 'numeric',
                    })
                    : ''}
            </DateCell>
            <TagCell>{datum.config.tag}</TagCell>
            <ScopeCell>
              {datum.config.scopePercentage}% {datum.config.scope}
            </ScopeCell>
            <ExpTypeCell>{datum.config.expType}</ExpTypeCell>
            <PrimaryMetricCell>{getPrimaryMetric(datum)}</PrimaryMetricCell>
            <VariantsCell>{datum.config.variants.length}</VariantsCell>
          </StyledRow>);
        }}
      </Table>
    </ExperimentTableCard>);
};
export default ExperimentTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
