import { useState, useEffect } from 'react';
import { sendError } from './error-tracker';
/**
 * Custom React hook that can be used to grab and store state in local storage.
 * In the server context, the initial state will be returned since local storage is unavailable.
 * This hook is intended to be used where the "useState" hook would be used.
 *
 * @param {string} key - local storage key to store state
 * @param {T | () => T} initialState - the initial state
 * @returns {[T, () => T]} the current state and state setter function in a list
 */
export default function useLocalStorage(key, initialState) {
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    useEffect(function () {
        var item = window.localStorage.getItem(key);
        if (item) {
            try {
                var parsedItem = JSON.parse(item);
                setState(parsedItem);
            }
            catch (err) {
                sendError(err);
            }
        }
    }, []);
    useEffect(function () {
        window.localStorage.setItem(key, JSON.stringify(state));
    }, [state]);
    return [state, setState];
}
