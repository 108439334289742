var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Link from 'next/link';
import Router from 'next/router';
import Button from '@leafygreen-ui/button';
import Badge, { Variant as BadgeVariant } from '@leafygreen-ui/badge';
import Card from '@leafygreen-ui/card';
import { palette } from '@leafygreen-ui/palette';
import { Body, Overline, Subtitle } from '@leafygreen-ui/typography';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Tooltip from '@leafygreen-ui/tooltip';
import { Option, Select } from '@leafygreen-ui/select';
import { MEDIA_MOBILE, NoTransformWrapper } from './Common';
import { ExpType, RunningStatus, RunningStatusLegacy } from '../shared/types/db_schema/experimentConfig';
import { getVariantCount } from '../utils/getVariantCount';
import { formatPval } from '../utils/roundToHundredth';
var ExperimentCardTitle = styled(Subtitle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin-bottom: 10px;\n  padding-right: 30px;\n  width: 100%;\n  font-weight: 600;\n  overflow-wrap: break-word;\n\n  a {\n    color: ", ";\n  }\n"], ["\n  display: block;\n  margin-bottom: 10px;\n  padding-right: 30px;\n  width: 100%;\n  font-weight: 600;\n  overflow-wrap: break-word;\n\n  a {\n    color: ", ";\n  }\n"])), palette.blue.base);
var CellLabel = styled(Overline)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-overflow: ellipsis;\n  font-weight: 600;\n  line-height: 16px;\n  text-transform: uppercase;\n  color: ", ";\n  padding-bottom: 4px;\n"], ["\n  text-overflow: ellipsis;\n  font-weight: 600;\n  line-height: 16px;\n  text-transform: uppercase;\n  color: ", ";\n  padding-bottom: 4px;\n"])), palette.gray.dark4);
var CellText = styled(Body)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), palette.gray.dark3);
var StyledExperimentCard = styled(Card)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: row;\n  padding-bottom: 21px;\n\n  &.experiment-card-LAUNCHED {\n    border: 2px solid ", ";\n    box-shadow: none;\n  }\n"], ["\n  margin-top: 16px;\n  display: flex;\n  flex-direction: row;\n  padding-bottom: 21px;\n\n  &.experiment-card-LAUNCHED {\n    border: 2px solid ", ";\n    box-shadow: none;\n  }\n"])), palette.green.dark1);
var StyledButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 90px;\n  height: 35px;\n"], ["\n  width: 90px;\n  height: 35px;\n"])));
var TitleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  min-width: 500px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  min-width: 500px;\n"])));
var ButtonWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n"])));
var Cell = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", " {\n    word-break: break-word;\n  }\n"], ["\n  ", " {\n    word-break: break-word;\n  }\n"])), MEDIA_MOBILE);
var Grid = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: grid;\n  width: 100%;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 25px;\n  overflow: hidden;\n\n  ", " {\n    grid-template-columns: repeat(2, 1fr);\n  }\n"], ["\n  display: grid;\n  width: 100%;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 25px;\n  overflow: hidden;\n\n  ", " {\n    grid-template-columns: repeat(2, 1fr);\n  }\n"])), MEDIA_MOBILE);
var SelectWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding-bottom: 3px;\n"], ["\n  padding-bottom: 3px;\n"])));
var StyledSelect = styled(Select)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: fit-content;\n"], ["\n  width: fit-content;\n"])));
var getStatusBadgeColor = function (status) {
    switch (status) {
        case RunningStatusLegacy.ENABLED:
        case RunningStatus.LIVE:
        case RunningStatus.COOLDOWN:
        case RunningStatus.LAUNCHED:
            return BadgeVariant.Green;
        case RunningStatusLegacy.DISABLED:
        case RunningStatus.COMPLETE:
            return BadgeVariant.Yellow;
        default:
            return BadgeVariant.LightGray;
    }
};
var expTypeToBadgeText = function (expType) {
    switch (expType) {
        case ExpType.MAB:
            return 'MAB';
        case ExpType.CMAB:
            return 'CMAB';
        case ExpType.HOLDOUT:
            return 'Holdout';
        default:
            return 'A/B';
    }
};
var ExperimentCard = function (_a) {
    var _b, _c, _d;
    var experiment = _a.experiment;
    var config = experiment.config, latestResults = experiment.latestResults;
    var experimentId = (_c = (_b = config._id) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : '';
    var _e = useState(), activeVariant = _e[0], setActiveVariant = _e[1];
    var _f = useState(), currentResult = _f[0], setCurrentResult = _f[1];
    var isAbType = config.expType === ExpType.AB;
    var isMabType = config.expType === ExpType.MAB;
    var isCmabType = config.expType === ExpType.CMAB;
    var isHoldoutType = config.expType === ExpType.HOLDOUT;
    var useControlView = isAbType || isCmabType || isHoldoutType;
    var primaryMetric = config.trackingMetrics.find(function (trackingMetric) { return trackingMetric.isPrimary; });
    var primaryMetricName = (_d = primaryMetric === null || primaryMetric === void 0 ? void 0 : primaryMetric.readableName) !== null && _d !== void 0 ? _d : '—';
    useEffect(function () {
        var firstVariant = config.variants.filter(function (variant) { return !variant.isControl; })[0];
        if (firstVariant) {
            setActiveVariant(firstVariant);
            // eslint-disable-next-line
            // @ts-ignore
            var selectedResult = latestResults.find(function (result) { return result.variantName === firstVariant.name; });
            if (selectedResult) {
                setCurrentResult(selectedResult);
            }
        }
    }, []);
    var handleChangeResult = function (value) {
        var targetVariant = config.variants.find(function (variant) { return variant.name === value; });
        if (targetVariant) {
            setActiveVariant(targetVariant);
            // eslint-disable-next-line
            // @ts-ignore
            var currentResult_1 = latestResults.find(function (result) { return result.variantName === targetVariant.name; });
            if (currentResult_1) {
                setCurrentResult(currentResult_1);
            }
        }
    };
    var result;
    var variantGroups = config === null || config === void 0 ? void 0 : config.variants.filter(function (variant) { return !variant.isControl; });
    if (variantGroups.length > 1 && activeVariant) {
        var selectOptions = variantGroups.map(function (variant) { return (<Option key={variant.name} value={variant.name}>
        {variant.name}
      </Option>); });
        result = (<SelectWrapper>
        <StyledSelect aria-labelledby="active-variant-select" value={activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.name} onChange={handleChangeResult} allowDeselect={false}>
          {selectOptions}
        </StyledSelect>
      </SelectWrapper>);
    }
    else {
        result = <CellText>{activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.name}</CellText>;
    }
    return (<StyledExperimentCard data-testid="experiment-card" className={"experiment-card-".concat(config.runningStatus)}>
      <TitleWrapper>
        <ExperimentCardTitle data-testid={"experiment-title-".concat(config._id)}>
          <Link href={"/".concat(useControlView ? 'details' : 'mab', "?experimentId=").concat(config._id, "&treatment=").concat(activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.name)}>
            {config === null || config === void 0 ? void 0 : config.name}
          </Link>
        </ExperimentCardTitle>
        <ButtonWrapper>
          <StyledButton data-testid={"edit-exp-button-".concat(config._id)} onClick={function () { return Router.push("/config?id=".concat(config._id)); }}>
            Edit
          </StyledButton>
          <Tooltip align="bottom" triggerEvent="click" trigger={<StyledButton aria-label="Copy Experiment ID" onClick={function () { return navigator.clipboard.writeText(experimentId); }}>
                Copy ID
              </StyledButton>}>
            Experiment ID Copied
          </Tooltip>
        </ButtonWrapper>
      </TitleWrapper>
      <Grid>
        <Cell>
          <CellLabel>STATUS</CellLabel>
          <Badge variant={getStatusBadgeColor(config.runningStatus)}>{config === null || config === void 0 ? void 0 : config.runningStatus}</Badge>
        </Cell>
        <Cell>
          <CellLabel>SCOPE</CellLabel>
          <CellText>
            {config.scopePercentage}%, {config.scope}
          </CellText>
        </Cell>
        <Cell>
          <CellLabel>Tag</CellLabel>
          <Badge variant={BadgeVariant.LightGray}>
            <NoTransformWrapper>{config.tag}</NoTransformWrapper>
          </Badge>
        </Cell>
        <Cell>
          <CellLabel>PRIMARY METRIC</CellLabel>
          <CellText>{primaryMetricName}</CellText>
        </Cell>
        <Cell>
          <CellLabel>TYPE</CellLabel>
          <Badge data-testid={"badge-".concat(config.expType)} variant={BadgeVariant.Blue}>
            {expTypeToBadgeText(config.expType)}
          </Badge>
        </Cell>
        <Cell>
          <CellLabel>START DATE</CellLabel>
          <CellText>
            {config.startDate
            ? new Date(config.startDate).toLocaleDateString('en-US', {
                year: '2-digit',
                month: 'numeric',
                day: 'numeric',
            })
            : '—'}
          </CellText>
        </Cell>
        <Cell>
          <CellLabel>{getVariantCount(config)}</CellLabel>
          {result}
        </Cell>
        {useControlView && (<Cell data-testid="cell-pvalue">
            <CellLabel>P-VALUE</CellLabel>
            <CellText>{formatPval(currentResult === null || currentResult === void 0 ? void 0 : currentResult.pValue)}</CellText>
          </Cell>)}
        {isMabType && (<Cell data-testid="cell-value">
            <CellLabel>VALUE</CellLabel>
            <CellText>{(currentResult === null || currentResult === void 0 ? void 0 : currentResult.value) || '—'}</CellText>
          </Cell>)}
      </Grid>
    </StyledExperimentCard>);
};
export default ExperimentCard;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
