var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useReducer, useState } from 'react';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
// leafygreen
import Toast, { Variant as ToastVariant } from '@leafygreen-ui/toast';
import { SegmentedControl, SegmentedControlOption } from '@leafygreen-ui/segmented-control';
import { Body, H2, Subtitle } from '@leafygreen-ui/typography';
import Icon from '@leafygreen-ui/icon';
import Toggle from '@leafygreen-ui/toggle';
import Tooltip from '@leafygreen-ui/tooltip';
import { Select, Option } from '@leafygreen-ui/select';
import Button from '@leafygreen-ui/button';
import Pagination from '@leafygreen-ui/pagination';
import { SearchInput } from '@leafygreen-ui/search-input';
import styled from 'styled-components';
import Loader from '../components/Loader';
import { PageInner } from '../components/Common';
import getExperiments from '../api/getExperiments';
import { ExpType, RunningStatus, Scope } from '../shared/types/db_schema/experimentConfig';
import { HOME_PAGE_ERROR_MSG, ITEMS_PER_PAGE_OPTIONS, ITEMS_PER_PAGE } from '../utils/constants';
import ExperimentTable from '../components/ExperimentTable';
import HoldoutCard from '../components/HoldoutCard';
import ExperimentCard from '../components/ExperimentCard';
import useLocalStorage from '../utils/useLocalStorage';
import getPrimaryMetric from '../utils/getPrimaryMetric';
var SectionSubtitle = styled(Subtitle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 24px;\n"], ["\n  padding-top: 24px;\n"])));
var HoldoutCardsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n  padding-top: 16px;\n  overflow-x: auto;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n  padding-top: 16px;\n  overflow-x: auto;\n"])));
var HoldoutsToggleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  gap: 14px;\n\n  position: absolute;\n  top: 40px;\n  right: 25px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  gap: 14px;\n\n  position: absolute;\n  top: 40px;\n  right: 25px;\n"])));
var DisplayHoldoutsText = styled(Body)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n"], ["\n  font-weight: 600;\n"])));
var ControlsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  padding-top: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  padding-top: 16px;\n"])));
var SegmentedControlWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 24px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding-top: 24px;\n"])));
var SegmentedControlLabelWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 6px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 6px;\n"])));
var SelectFiltersWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  gap: 20px;\n  align-items: end;\n"], ["\n  display: flex;\n  gap: 20px;\n  align-items: end;\n"])));
var SelectFilter = styled(Select)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 140px;\n"], ["\n  width: 140px;\n"])));
var SelectFilterWider = styled(Select)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 170px;\n"], ["\n  width: 170px;\n"])));
var StyledSearchInput = styled(SearchInput)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 330px;\n  margin-top: 16px;\n"], ["\n  width: 330px;\n  margin-top: 16px;\n"])));
var StyledPagination = styled(Pagination)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var NoResultsWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 150px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 150px;\n"])));
var selectFiltersReducer = function (state, action) {
    switch (action.type) {
        case 'CHANGE_STATUS':
            return __assign(__assign({}, state), { runningStatus: action.payload });
        case 'CHANGE_TAG':
            return __assign(__assign({}, state), { tag: action.payload });
        case 'CHANGE_EXP_TYPE':
            return __assign(__assign({}, state), { expType: action.payload });
        case 'CHANGE_PRIMARY_METRIC':
            return __assign(__assign({}, state), { primaryMetric: action.payload });
        case 'CHANGE_SCOPE':
            return __assign(__assign({}, state), { scope: action.payload });
        case 'CHANGE_ALL_FILTERS':
            return __assign({}, action.payload);
        default:
            return state;
    }
};
// Returns the list of experiments with the select filters applied to them
var applySelectFilters = function (experiments, filters) {
    return experiments.filter(function (experiment) {
        var filterRunningStatus = true;
        if (filters.runningStatus) {
            filterRunningStatus = experiment.config.runningStatus === filters.runningStatus;
        }
        var filterTag = true;
        if (filters.tag) {
            filterTag = experiment.config.tag === filters.tag;
        }
        var filterExpType = true;
        if (filters.expType) {
            filterExpType = experiment.config.expType === filters.expType;
        }
        var filterPrimaryMetric = true;
        if (filters.primaryMetric) {
            filterPrimaryMetric = getPrimaryMetric(experiment) === filters.primaryMetric;
        }
        var filterScope = true;
        if (filters.scope) {
            filterScope = experiment.config.scope === filters.scope;
        }
        return filterRunningStatus && filterTag && filterExpType && filterPrimaryMetric && filterScope;
    });
};
// Returns true if the filter values matches the default settings
var isDefaultAppliedFilters = function (filters) { return Object.values(filters).every(function (v) { return v === ''; }); };
var defaultSelectFilters = {
    runningStatus: '',
    tag: '',
    expType: '',
    primaryMetric: '',
    scope: '',
};
function HomePage(_a) {
    var _this = this;
    var _b, _c, _d;
    var user = _a.user;
    var _e = useState(), experiments = _e[0], setExperiments = _e[1];
    var _f = useState(), activeHoldouts = _f[0], setActiveHoldouts = _f[1];
    var _g = useState(true), isLoading = _g[0], setIsLoading = _g[1];
    var _h = useState(false), toastOpen = _h[0], setToastOpen = _h[1];
    var _j = useState(null), toastConfig = _j[0], setToastConfig = _j[1];
    var _k = useState(true), toggleOn = _k[0], setToggleOn = _k[1];
    var _l = useLocalStorage('settings.table-view', false), showTableView = _l[0], setShowTableView = _l[1];
    var _m = useLocalStorage('settings.select-filters', defaultSelectFilters), appliedSelectFilters = _m[0], setAppliedSelectFilters = _m[1];
    var _o = useReducer(selectFiltersReducer, appliedSelectFilters), currentSelectFilters = _o[0], currentSelectFiltersDispatch = _o[1];
    var _p = useState(''), searchValue = _p[0], setSearchValue = _p[1];
    // pagination state
    var _q = useState(1), currentPage = _q[0], setCurrentPage = _q[1];
    var _r = useState(ITEMS_PER_PAGE), itemsPerPage = _r[0], setItemsPerPage = _r[1];
    useEffect(function () {
        if (user) {
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var experimentsFromApi, activeHoldoutExperiments, error_1, parsedError;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, getExperiments(user)];
                        case 1:
                            experimentsFromApi = _a.sent();
                            activeHoldoutExperiments = experimentsFromApi.experiments.filter(function (experiment) {
                                return experiment.config.expType === ExpType.HOLDOUT &&
                                    (experiment.config.runningStatus === RunningStatus.LIVE ||
                                        experiment.config.runningStatus === RunningStatus.COOLDOWN);
                            });
                            setActiveHoldouts(activeHoldoutExperiments);
                            setExperiments(experimentsFromApi.experiments);
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _a.sent();
                            parsedError = JSON.parse(JSON.stringify(error_1));
                            displayToast({ title: HOME_PAGE_ERROR_MSG, body: parsedError.error, variant: ToastVariant.Warning });
                            return [3 /*break*/, 3];
                        case 3:
                            setIsLoading(false);
                            return [2 /*return*/];
                    }
                });
            }); })();
        }
    }, [user]);
    // Apply the cached applied select filters state to the current select filter elements
    useEffect(function () {
        if (isEqual(currentSelectFilters, appliedSelectFilters)) {
            return;
        }
        currentSelectFiltersDispatch({ type: 'CHANGE_ALL_FILTERS', payload: appliedSelectFilters });
    }, [appliedSelectFilters]);
    var displayToast = function (_a) {
        var title = _a.title, body = _a.body, variant = _a.variant;
        setToastConfig({ title: title, body: body, variant: variant });
        setToastOpen(true);
    };
    // show LAUNCHED experiments in reverse chronological order before other experiments
    var sortedExperiments = experiments
        ? __spreadArray([], experiments, true).sort(function (a, b) {
            if (a.config.runningStatus === RunningStatus.LAUNCHED && b.config.runningStatus !== RunningStatus.LAUNCHED) {
                return -1;
            }
            if (a.config.runningStatus !== RunningStatus.LAUNCHED && b.config.runningStatus === RunningStatus.LAUNCHED) {
                return 1;
            }
            return a.config.launchDate && b.config.launchDate
                ? b.config.launchDate.valueOf() - a.config.launchDate.valueOf()
                : 0;
        })
        : [];
    if (searchValue) {
        sortedExperiments = sortedExperiments.filter(function (experiment) {
            return experiment.config.name.toLowerCase().includes(searchValue.toLowerCase());
        });
    }
    if (sortedExperiments && !isDefaultAppliedFilters(appliedSelectFilters)) {
        sortedExperiments = applySelectFilters(sortedExperiments, appliedSelectFilters);
    }
    var lowerPageBound = (currentPage - 1) * itemsPerPage;
    var upperPageBound = lowerPageBound + itemsPerPage;
    var totalNumOfExperiments = sortedExperiments.length;
    if (sortedExperiments.length > itemsPerPage) {
        sortedExperiments = sortedExperiments.slice(lowerPageBound, upperPageBound);
    }
    var experimentCards = sortedExperiments &&
        sortedExperiments.map(function (experiment, index) { return (<ExperimentCard key={"".concat(experiment.config.name, "-").concat(index)} experiment={experiment}/>); });
    var experimentTable = sortedExperiments && <ExperimentTable experiments={sortedExperiments}/>;
    var experimentDisplay = showTableView ? experimentTable : experimentCards;
    var tags = experiments ? uniq(experiments.map(function (experiment) { return experiment.config.tag; })).sort() : [];
    var primaryMetrics = experiments ? uniq(experiments.map(getPrimaryMetric)).sort() : [];
    var holdoutCards = activeHoldouts &&
        activeHoldouts.map(function (holdout) { return <HoldoutCard key={holdout.config.name} experiment={holdout}/>; });
    var activeHoldoutsExist = holdoutCards !== undefined && holdoutCards.length > 0;
    return (<>
      <Loader isLoading={isLoading} message={'Loading experiments...'}/>
      <Toast data-testid="toast" variant={(_b = toastConfig === null || toastConfig === void 0 ? void 0 : toastConfig.variant) !== null && _b !== void 0 ? _b : ToastVariant.Success} title={(_c = toastConfig === null || toastConfig === void 0 ? void 0 : toastConfig.title) !== null && _c !== void 0 ? _c : ''} body={(_d = toastConfig === null || toastConfig === void 0 ? void 0 : toastConfig.body) !== null && _d !== void 0 ? _d : ''} open={toastOpen} close={function () { return setToastOpen(false); }}/>
      <PageInner>
        <H2>Experiments Overview</H2>
        {activeHoldoutsExist ? (<HoldoutsToggleWrapper>
            <DisplayHoldoutsText>Display Holdouts</DisplayHoldoutsText>
            <Toggle aria-label="Display Holdouts" size="small" checked={toggleOn} onChange={function () { return setToggleOn(!toggleOn); }}></Toggle>
          </HoldoutsToggleWrapper>) : (<Tooltip align="top" popoverZIndex={21} trigger={<HoldoutsToggleWrapper>
                <DisplayHoldoutsText>Display Holdouts</DisplayHoldoutsText>
                <Toggle aria-label="Display Holdouts" size="small" disabled={true} checked={toggleOn} onChange={function () { return setToggleOn(!toggleOn); }}></Toggle>
              </HoldoutsToggleWrapper>}>
            No Active Holdouts
          </Tooltip>)}
        {activeHoldoutsExist && toggleOn && (<>
            <SectionSubtitle data-testid="holdouts-subtitle">Active Holdouts</SectionSubtitle>
            <HoldoutCardsWrapper>{holdoutCards}</HoldoutCardsWrapper>
            <SectionSubtitle>All Experiments</SectionSubtitle>
          </>)}
        <StyledSearchInput onFocus={function () { return setCurrentPage(1); }} aria-label="searchInput" value={searchValue} onChange={function (e) { return setSearchValue(e.target.value); }} placeholder="Search experiments by name"/>
        <ControlsWrapper>
          <SelectFiltersWrapper>
            <SelectFilter label="Status" value={currentSelectFilters.runningStatus} onChange={function (value) {
            currentSelectFiltersDispatch({ type: 'CHANGE_STATUS', payload: value });
        }}>
              {[
            RunningStatus.COMPLETE,
            RunningStatus.COOLDOWN,
            RunningStatus.DELETED,
            RunningStatus.DRAFT,
            RunningStatus.LAUNCHED,
            RunningStatus.LIVE,
        ].map(function (status) { return (<Option key={status} value={status} aria-label={status}>
                  {status}
                </Option>); })}
            </SelectFilter>
            <SelectFilterWider label="Tag" value={currentSelectFilters.tag} onChange={function (value) {
            currentSelectFiltersDispatch({ type: 'CHANGE_TAG', payload: value });
        }}>
              {tags.map(function (tag) { return (<Option key={tag} value={tag}>
                  {tag}
                </Option>); })}
            </SelectFilterWider>
            <SelectFilter label="Experiment Type" value={currentSelectFilters.expType} onChange={function (value) {
            currentSelectFiltersDispatch({ type: 'CHANGE_EXP_TYPE', payload: value });
        }}>
              {[ExpType.AB, ExpType.CMAB, ExpType.HOLDOUT, ExpType.MAB].map(function (expType) { return (<Option key={expType} value={expType} aria-label={expType}>
                  {expType}
                </Option>); })}
            </SelectFilter>
            <SelectFilter label="Scope" value={currentSelectFilters.scope} onChange={function (value) {
            currentSelectFiltersDispatch({ type: 'CHANGE_SCOPE', payload: value });
        }}>
              {Object.values(Scope).map(function (scope) { return (<Option key={scope} value={scope} aria-label={scope}>
                  {scope}
                </Option>); })}
            </SelectFilter>
            <SelectFilterWider label="Primary Metric" value={currentSelectFilters.primaryMetric} onChange={function (value) {
            currentSelectFiltersDispatch({ type: 'CHANGE_PRIMARY_METRIC', payload: value });
        }}>
              {primaryMetrics.map(function (metric) { return (<Option key={metric} value={metric}>
                  {metric}
                </Option>); })}
            </SelectFilterWider>
            <Button disabled={isEqual(currentSelectFilters, appliedSelectFilters)} onClick={function () {
            setAppliedSelectFilters(currentSelectFilters);
        }}>
              <Icon glyph="Filter"/>
              Apply Filters
            </Button>
          </SelectFiltersWrapper>
          <SegmentedControlWrapper>
            <SegmentedControl name="experiment view" followFocus={true} defaultValue={'card'} value={showTableView ? 'list' : 'card'} onChange={function () { return setShowTableView(!showTableView); }} data-testid="segmented-control">
              <SegmentedControlOption aria-controls="card" value={'card'}>
                <SegmentedControlLabelWrapper>
                  <Icon glyph="Apps"/>
                  card
                </SegmentedControlLabelWrapper>
              </SegmentedControlOption>
              <SegmentedControlOption aria-controls="list" value={'list'}>
                <SegmentedControlLabelWrapper>
                  <Icon glyph="Unsorted"/>
                  list
                </SegmentedControlLabelWrapper>
              </SegmentedControlOption>
            </SegmentedControl>
          </SegmentedControlWrapper>
        </ControlsWrapper>
        {sortedExperiments.length ? experimentDisplay : <NoResultsWrapper>No results found</NoResultsWrapper>}
        <StyledPagination data-testid="pagination" currentPage={currentPage} itemsPerPage={itemsPerPage} itemsPerPageOptions={ITEMS_PER_PAGE_OPTIONS} numTotalItems={totalNumOfExperiments !== null && totalNumOfExperiments !== void 0 ? totalNumOfExperiments : 0} onItemsPerPageOptionChange={function (value) { return setItemsPerPage(Number(value)); }} onForwardArrowClick={function () {
            setCurrentPage(function (prev) { return prev + 1; });
        }} onBackArrowClick={function () {
            setCurrentPage(function (prev) { return prev - 1; });
        }}/>
      </PageInner>
    </>);
}
export default HomePage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
